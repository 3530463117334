const host = process.env.HOST_TARGET;
let color = 'rgba(0, 0, 150, 1)';
let text = 'localhost';
if (host === 'dev') {
  color = 'rgba(0, 150, 0, 1)';
  text = 'dev.voltus.co';
}
const watermark = document.createElement('div');
watermark.style.position = 'fixed';
watermark.style.top = '4px';
watermark.style.left = '150px';
watermark.style.pointerEvents = 'none';
watermark.style.zIndex = 99999;
watermark.style.padding = '2px';
watermark.style.backgroundColor = color;
watermark.style.color = 'white';
watermark.style.borderRadius = '4px';
watermark.innerHTML = `host: ${text}`;

// document.body.appendChild(watermark)