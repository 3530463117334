export const getScrollOffset = (): { x: number; y: number } => {
  const body = document.body
  const docEl = document.documentElement

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0
  return {
    x: scrollLeft - clientLeft,
    y: scrollTop - clientTop,
  }
}

/**
 * Recursively walks up the DOM tree and accumulates the offsetTop for a given element
 * Useful to find the position of an element relative to the top of `document.body`.
 */
export const getOffsetPosition = (elt: HTMLElement) => {
  let curtop = 0
  let curleft = 0
  let offsetParent = elt.offsetParent
  while (offsetParent) {
    curleft += elt.offsetLeft
    curtop += elt.offsetTop
    // Need the cast because offsetParent is Element, not HTMLElement, since
    // the compiler can't know if it's truly an HTMLElement - for example, it _could_
    // be an SVGElement. But we don't care about that case, so cast to HTMLElement.
    offsetParent = (offsetParent as HTMLElement)?.offsetParent
  }
  return [curleft, curtop] as const
}

export const getBoundingBox = (element: Element): DOMRect => {
  // crossbrowser version
  const box = element.getBoundingClientRect()
  const scrollOffset = getScrollOffset()

  const top = box.top + scrollOffset.y
  const left = box.left + scrollOffset.x

  const rect = {
    top: top,
    left: left,
    width: box.width,
    height: box.height,
    bottom: top + box.height,
    right: left + box.width,
    x: left,
    y: top,
  }
  return {
    ...rect,
    // To make typescript happy, implement the full interface of DOMRect
    toJSON: () => rect,
  }
}

export function checkInView(
  container: HTMLElement,
  element: HTMLElement,
  topPctVisible = 1,
  bottomPctVisible = 1
) {
  //Get container properties
  const cTop = container.scrollTop
  const cBottom = cTop + container.clientHeight

  //Get element properties
  const eTop = element.offsetTop - container.offsetTop
  const eBottom = eTop + element.clientHeight

  //Check if in view
  const isTotal = eTop >= cTop && eBottom <= cBottom
  if (isTotal) {
    return true
  }

  let diff = 0
  let pctVisible = 0

  if (eTop < cTop) {
    diff = eBottom - cTop
    pctVisible = diff / element.clientHeight
    if (pctVisible >= topPctVisible) {
      return true
    }
  }

  if (eBottom > cBottom) {
    const diff = cBottom - eTop
    pctVisible = diff / element.clientHeight
    if (pctVisible >= bottomPctVisible) {
      return true
    }
  }

  return false
}

export const scrollIntoViewById = (
  id: string,
  opts: { behavior: ScrollOptions['behavior'] }
) => {
  const elt = document.getElementById(id)
  if (elt) {
    elt?.scrollIntoView({ behavior: opts?.behavior ?? 'smooth' })
  }
}

/**
 * A utility function that waits for scrolling to end.
 * Returns a promise that resolves when the page has not scrolled for 20 frames.
 */
export function waitForScrollEnd() {
  let lastChangedFrame = 0
  let lastX = window.scrollX
  let lastY = window.scrollY

  return new Promise<void>((resolve) => {
    function tick(frames) {
      // We requestAnimationFrame either for 500 frames or until 20 frames with
      // no change have been observed.
      if (frames >= 500 || frames - lastChangedFrame > 20) {
        resolve()
      } else {
        if (window.scrollX !== lastX || window.scrollY !== lastY) {
          lastChangedFrame = frames
          lastX = window.scrollX
          lastY = window.scrollY
        }
        requestAnimationFrame(tick.bind(null, frames + 1))
      }
    }
    tick(0)
  })
}
